import ImageGallery from 'react-image-gallery';
import React from 'react'
import images from '../Carousel'
import '../index.css';

function importAll(r) {
    return r.keys().map(r);
  }

const image_arr_2023 = importAll(require.context('../images/2023-pics', false, /\.(png|jpe?g|svg)$/));


class Updates extends React.Component {
  render() {
    return [
        <p className="carousel-header-top">2023</p>,
        <ImageGallery key={1} items={images.images23} />,
        <p className="carousel-header-top">2022</p>,
        <ImageGallery key={2} items={images.images22} />,
        <p className="carousel-header">2021</p>,
        <ImageGallery key={3} items={images.images21} />,
        <p className="carousel-header">2020</p>,
        <ImageGallery key={4} items={images.images20} />,
        <p className="carousel-header">2019</p>,
        <ImageGallery key={5} items={images.images19} />,
        <p className="carousel-header">2018</p>,
        <ImageGallery key={6} items={images.images18} />
    ]
  }
}

export default Updates;
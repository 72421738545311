import React from 'react';

const Contact = () => (
  <>
    <h1> CONTACT </h1>
    {/* <br /> */}
    <div class='table-wrapper'>
      <div class='row'>
        <div class='column phone'>
            <h3>CALL OR TEXT</h3>
            <p> Nathan Dupont<br></br>
            (414) 550-2204 </p>
        </div>
        <div class='column email'>
          <h3>EMAIL</h3>
          <p>Questions or comments? Send them to:<br></br>holidayjoydrive@gmail.com</p>
        </div>
        <div class='column home'>
          <h3>ADDRESS</h3>
          <p> Want to ship presents directly to us? <br></br><br></br>Address them to:<br></br>
            Attn: Nathan Dupont<br></br>
            3302 S. Adams Avenue<br></br>
            Milwaukee, WI 53207
          </p>
        </div>
        
      </div>
    </div>
  </>
);

export default Contact;
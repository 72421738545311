import React from 'react';
import nathan from '../images/nathan.jpg';

const About = () => (
  <>
    <div className="main-text">
      <img id="main-image" className="rcorners" src={ nathan } alt="Nathan and 2022 Toys" />
      <p> 
        Holiday Joy Drive was created in 2018 by Nathan Dupont as a way to make the holidays a little brighter for children in the Milwaukee and Madison areas.
        <br /><br />The drive is an opportunity for all of us to change the world we live in by lifting up our communities. In six years, we've raised 4,221 toys valued at just under $75,000.
        <br /><br />Each year, toy donations and cash (used to purchase additional gifts) are both accepted and incredibly appreciated. Toys benefit the Boys and Girls Clubs of Greater Milwaukee and Dane County and are distributed to club locations belonging to each chapter.
        <br /><br />Hundreds of individuals, families and companies have made the Drive their own over the past four years by introducing it to other people in their lives. Without that creativity and generosity, the Drive wouldn't be possible.
        <br /><br />Every gift makes a kid's holiday experience a little happier. With your help, we can continue to make a difference for hundreds of kids and families this year.
        <br /><br /><br /><h3>About Nathan</h3>Nathan is a Hales Corners, WI native and Whitnall High School graduate. He graduated from UW-Madison in 2016 and currently resides in Milwaukee.    
        <br /><br />Please consider donating and sharing the Holiday Joy Drive mission with your friends, family and anyone looking to give back this holiday season. No matter how you choose to give back to your community, it truly makes a difference in someone's life. Thank you for your support!  
      </p>
    </div>
  </>
  
);

export default About;